.first-place {
  background: linear-gradient(
      90deg,
      rgba(243, 207, 55, 0.25) 0%,
      rgba(143, 92, 6, 0.25) 100%
    ),
    var(--Background-Light, #f1f1f1);
}

.second-place {
  background: linear-gradient(
      90deg,
      rgba(211, 213, 215, 0.2) 0%,
      rgba(111, 116, 123, 0.2) 100%
    ),
    var(--Background-Light, #f1f1f1);
}

.third-place {
  background: linear-gradient(
      90deg,
      rgba(249, 115, 22, 0.25) 0%,
      rgba(147, 68, 13, 0.25) 100%
    ),
    var(--Background-Lightest, #fbfbfb);
}

.first-place-dark {
  background: linear-gradient(
      90deg,
      rgba(243, 207, 55, 0.25) 0%,
      rgba(143, 92, 6, 0.25) 100%
    ),
    var(--Background-Light, #1e1c21);
}

.second-place-dark {
  background: linear-gradient(
      90deg,
      rgba(211, 213, 215, 0.2) 0%,
      rgba(111, 116, 123, 0.2) 100%
    ),
    var(--Background-Light, #1e1c21);
}

.third-place-dark {
  background: linear-gradient(
    90deg,
    rgba(211, 213, 215, 0.2) 0%,
    rgba(111, 116, 123, 0.2) 100%
  );
}
